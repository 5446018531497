/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';
import { CustomerValuePojo } from './customer-value-pojo';
import { ActivityChecklistItemStatusPojo } from './activity-checklist-item-status-pojo';
import { PortalUserPojo } from './portal-user-pojo';


export interface ActivityChecklistItemPojo { 
    id?: number;
    activity?: string;
    comment?: string;
    responsible?: NameIdPojo;
    status?: ActivityChecklistItemPojo.StatusEnum;
    actualStartDate?: string;
    expectedStartDate?: string;
    completionDate?: string;
    dueDate?: string;
    createdAt?: string;
    createdBy?: PortalUserPojo;
    customerValuePojo?: CustomerValuePojo;
    activityChecklistItemStatusPojoList?: Array<ActivityChecklistItemStatusPojo>;
}
export namespace ActivityChecklistItemPojo {
    export type StatusEnum = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';
    export const StatusEnum = {
        NotStarted: 'NOT_STARTED' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Done: 'DONE' as StatusEnum
    };
}


