/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HardwareDevicePojo } from './hardware-device-pojo';
import { OperatingSystemVersionPojo } from './operating-system-version-pojo';
import { SoftwareServerPojo } from './software-server-pojo';
import { HardwareDeploymentEnvironmentPojo } from './hardware-deployment-environment-pojo';
import { NameIdPojo } from './name-id-pojo';
import { DataCenterPojo } from './data-center-pojo';
import { ConfigurationFileMetaDataPojo } from './configuration-file-meta-data-pojo';
import { RackPojo } from './rack-pojo';
import { OperatingSystemPojo } from './operating-system-pojo';


export interface HardwareProductionUnitPojo { 
    id?: number;
    identifier?: string;
    purpose?: string;
    virtualMachineName?: string;
    virtualMachineIpAddress?: string;
    platformId?: number;
    createdAt?: string;
    createdBy?: NameIdPojo;
    operatingSystem?: OperatingSystemPojo;
    operatingSystemVersion?: OperatingSystemVersionPojo;
    hardwareDevice?: HardwareDevicePojo;
    hardwareDeploymentEnvironment?: HardwareDeploymentEnvironmentPojo;
    configurationFileMetaDataList?: Array<ConfigurationFileMetaDataPojo>;
    dataCenter?: DataCenterPojo;
    softwareServer?: SoftwareServerPojo;
    rack?: RackPojo;
    hardwareDeviceOperatingSystem?: OperatingSystemPojo;
    hardwareDeviceOperatingSystemVersion?: OperatingSystemVersionPojo;
    sourceUrl?: string;
    mappedProductionUnitIds?: Array<number>;
    assetType?: string;
    hardwareModel?: string;
    serialNumber?: string;
}

