import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit{
  showPageHeader = true
  showPageFooter = true
  showAuthHeader = false
  showExecution = false
  menuToggled = false
  sidePanelToggled = false

  constructor(
    private navigationService: NavigationService,
    private activatedroute: ActivatedRoute
  ) {
    this.activatedroute.data.subscribe((data : any) => {
      this.showPageHeader = data.showPageHeader;
      this.showPageFooter = data.showPageFooter;
      this.showAuthHeader = data.showAuthHeader;
    })
  }

  ngOnInit(): void {
    this.navigationService.toggleStatus.asObservable().subscribe((status) => {
      this.menuToggled = status
    });
    this.showExecution = this.getShowExecutionMenu(this.activatedroute);
  }

   getShowExecutionMenu(route: ActivatedRoute): boolean {
    let data = route.snapshot.data;
    if (data && data['showExecution'] !== undefined) {
      return data['showExecution'];
    }

    let showExecution = false;

    for (const child of route.children) {
      data = child.snapshot.data;
      if (data && data['showExecution'] !== undefined) {
        showExecution = data['showExecution'];
        return showExecution;
      }
    }

    return showExecution;

  }

}
