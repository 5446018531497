import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    NameIdPojo,
    ProductionProcessComplianceEntryPojo,
    ProductionProcessStagePojo, ProductionUnitControllerService,
    ProductionUnitLifecycleControllerService,
    ProductionUnitLifecycleTracker,
    SoftwareProductionUnitPojo
} from "../../../../../../sdk/customer-fulfillment-api-sdk";
import {BsModalRef} from "ngx-bootstrap/modal";
import {HelperService} from "../../../../services/helper.service";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {mergeMap, Observable} from "rxjs";
import {map, tap} from "rxjs/operators";
import CurrentStateEnum = SoftwareProductionUnitPojo.CurrentStateEnum;
import {environment} from "../../../../../environments/environment";
import CurrentStatusEnum = ProductionUnitLifecycleTracker.CurrentStatusEnum;
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-production-unit-lifecycle',
    templateUrl: './production-unit-lifecycle.component.html',
    styleUrls: ['./production-unit-lifecycle.component.scss']
})
export class ProductionUnitLifecycleComponent implements OnInit {

    @Input() title: string;

    @Input() productionUnit: SoftwareProductionUnitPojo;

    @Input() complianceStages$: Observable<ProductionProcessStagePojo[]>;

    @Input() processCompliancePojo: ProductionProcessComplianceEntryPojo;

    complianceId: number

    complianceStages: ProductionUnitLifecycleTracker[] = [];

    tabHeaders: string[] = []
    tabIcons: string[] = [
        'weui:done2-outlined',
        'carbon:in-progress',
        'tabler:progress',
        'icons8:cancel'
    ];

    @Output()
    alert = new EventEmitter<{ msg: string; type: AlertType }>();
    showAlertMessageTrigger = false;
    protected readonly AlertType = AlertType;
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    puForm: FormGroup;


    constructor(private modalRef: BsModalRef,
                protected helperService: HelperService,
                private fb: FormBuilder,
                private puService: ProductionUnitControllerService,
                private processComplianceService: ProductionUnitLifecycleControllerService) {

    }

    lastLogged(item: ProductionUnitLifecycleTracker): boolean {
        return this.getProcessCode(this.productionUnit.currentState) === item.processCode;
    }

    getIcon(item: ProductionUnitLifecycleTracker): string {

        if (this.logged(item)) {
            if (item.currentStatus == CurrentStatusEnum.Pass)
                return this.tabIcons[0]
            else
                return this.tabIcons[3]
        }

        if (this.lastLogged(item)) {
            return this.tabIcons[1];
        }

        return this.tabIcons[2]
    }

    logged(item: ProductionUnitLifecycleTracker): boolean {
        return item.failCount > 0 || item.passCount > 0;
    }

    ngOnInit(): void {
        if (this.processCompliancePojo) {
            this.initPuForm();
            this.setStages(this.processCompliancePojo);
        }
        else this.setStages();

    }

    initPuForm(): void {
        this.puForm = this.fb.group({
            pu: [this.processCompliancePojo?.productionUnits[0]?.id]
        });

        this.puForm.controls['pu'].valueChanges.pipe()
            .subscribe((value: number) => {
            this.switchPU(value);
        });
    }


    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getAlertMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    /////////////////////
    //////END ALERT
    /////////////////////


    close(): void {
        this.modalRef.hide();
    }


    setStages(entry?: ProductionProcessComplianceEntryPojo) {
        const pu = entry?.productionUnits?.length > 0
            ? entry.productionUnits[0]
            : this.productionUnit;

        if (!pu) {
            this.close();
            return;
        }

        this.initFirstPU(pu)

        this.complianceStages$.pipe(
            map(v => {
                this.complianceStages = v.sort(
                    (a, b) => a.stageNumber - b.stageNumber
                ).map(v => {
                    return {
                        processCode: v.code,
                        processName: v.name,
                        stageNumber: v.stageNumber
                    }
                });
                this.tabHeaders = this.complianceStages.map(x => x.processName);
                return v;
            }),

            mergeMap(() =>
                this.processComplianceService.getEntries({
                    puId: pu.id,
                }).pipe(
                    tap((lifecycleEvents) =>  {
                        this.complianceStages = lifecycleEvents.sort(
                            (a, b) => a.stageNumber - b.stageNumber
                        );
                    })
                )
            ),
        ).subscribe({
            next: result => {
                // Handle the final result if needed
            },
            error: err => {
                // Handle errors
                console.error(err);
            }
        });
    }

    getBackground(item): string {
        const a = true;
        return a ? 'rgba(58, 222, 114, 0.10)' : 'rgba(219, 143, 17, 0.10)'
    }

    getLeftBorderStyle(item: ProductionUnitLifecycleTracker) {
       return this.lastLogged(item) ? '5px solid #DB8F11' : this.logged(item) ? '5px solid green' : '5px solid #9C9C9B'
    }

    getProcessCode(currentState: CurrentStateEnum): string {
        switch (currentState) {

            case CurrentStateEnum.Design:
            case CurrentStateEnum.DesignFail:
                return environment.designApprovalProcessCode;

            case CurrentStateEnum.ReleasePlanning:
            case CurrentStateEnum.ReleasePlanningFail:
                return environment.mrpApprovalProcessCode;

            case CurrentStateEnum.FunctionalTesting:
            case CurrentStateEnum.FunctionalTestingFail:
                return environment.qaProcessCode;

            case CurrentStateEnum.Release:
            case CurrentStateEnum.ReleaseFail:
                return environment.releaseProcessCode;

            case CurrentStateEnum.UserAcceptanceTesting:
            case CurrentStateEnum.UserAcceptanceTestingFail:
                return environment.UATProcessCode;

            case CurrentStateEnum.CodeReview:
            case CurrentStateEnum.CodeReviewFail:
                return environment.ciProcessCode;

            case CurrentStateEnum.BetaSuccess:
                return environment.monitoringProcessCode;

            default:
                return null;

        }
    }

    switchPU(id: number) {
        this.puService.searchSoftwareProductionUnit({
            id: id
        }).pipe(
            map(v => {
                this.productionUnit = v.results[0];
                return v;
            }),
            mergeMap(() =>
                this.processComplianceService.getEntries({
                    puId: this.productionUnit.id,
                }).pipe(
                    tap((lifecycleEvents) =>  {
                        this.complianceStages = lifecycleEvents.sort(
                            (a, b) => a.stageNumber - b.stageNumber
                        );
                    })
                )
            )
        ).subscribe({
            next: result => {
                // Handle the final result if needed
            },
            error: err => {
                // Handle errors
                console.error(err);
            }
        });
    }

    private initFirstPU(pu: NameIdPojo) {
        if (this.productionUnit) return;
        this.puService.searchSoftwareProductionUnit({
            id: pu.id
        }).subscribe(v => {
            this.productionUnit = v.results[0];
        })
    }
}
