<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getErrorMessage() }}
</div>

<form [formGroup]="form">

    <div *ngIf="isDelegation" class="d-flex justify-content-between mt-5 mx-3 mb-2 w-100 flex-wrap">
        <div class="d-flex justify-content-between mb-3 col-md-4">
            <p class="fw-bold fs-16">Search C5</p>
        </div>
        <div class="d-flex flex-wrap col-md-8 gap-2">
            <div class="col-md-7 col-12 mb-3 mb-md-0">
                <input type="text" class="form-control" style="padding:.8rem!important" [formControlName]="'keyword'"
                       placeholder="Search by Customer Value, Customer Value ID or any keyword">
            </div>
            <div class="col-md-4 col-12">
                <button [disabled]="searchManager.working" class="btn btn-dark px-3 d-flex align-items-center" (click)="submit()">
                   <ng-container *ngIf="!searchManager.working">
                       <i>
                           <i class="iconify icon me-2" data-icon="iconamoon:search-thin"></i>
                       </i>
                   </ng-container>
                    <app-loading *ngIf="searchManager.working"></app-loading>
                    Search
                </button>
            </div>
        </div>
    </div>



    <div *ngIf="!isDelegation"
         class="mt-3 mb-5 mx-3 p-3 search bg-white row g-3 d-sm-flex align-items-center justify-content-between">
        <div class="col-md-5 mt-0">
            <div class="material-form">
                <input type="email" class="form-control" [formControlName]="'keyword'"
                       placeholder="Search by Customer Value, Customer Value ID or any keyword">
            </div>
        </div>
        <div class="col-md-7">
            <div class="d-flex align-items-center float-end" [formGroup]="form">
                <div class="dropdown">
                    <button class="btn btn-outline-secondary px-3 d-flex align-items-center" role="button"
                            id="dropdownMenuLink1"
                            data-bs-toggle="dropdown" aria-expanded="false"><span class="iconify icon me-2"
                                                                                  data-icon="solar:sort-line-duotone"></span>Filter
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end mt-3 p-3"
                        style="width: 270px; border-radius: 10px; box-shadow: 2px 4px 13px 0px rgba(0, 0, 0, 0.10);"
                        aria-labelledby="dropdownMenuLink1">
                        <li style="cursor: pointer">
                            <div class="mb-3">
                                <label class="form-label">Date Created From</label>
                                <input
                                        type="text"
                                        class="form-control"
                                        formControlName="createdAfter"
                                        placeholder="DD-MM-YYYY"
                                        #dp1="bsDatepicker"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' }"
                                        bsDatepicker
                                />
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Date Created To</label>
                                <input
                                        type="text"
                                        class="form-control"
                                        formControlName="createdBefore"
                                        placeholder="DD-MM-YYYY"
                                        #dp2="bsDatepicker"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' }"
                                        [maxDate]="now()"
                                        [minDate]="getMinDate()"
                                        bsDatepicker
                                />
                                <error-message [form]="form" [controlName]="'createdBefore'"></error-message>
                            </div>
                            <div class="mb-3">
                                <button class="btn btn-dark w-100" (click)="submit()"><span class="iconify icon"
                                                                                            data-icon="iconamoon:search-thin"></span>Search
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <button [disabled]="searchManager.working"
                        class="btn btn-dark ms-3 px-3 d-flex align-items-center" (click)="submit()">
                    <i *ngIf="!searchManager.working">
                        <i class="iconify icon me-2"
                           data-icon="iconamoon:search-thin"></i>
                    </i>
                    <app-loading *ngIf="this.searchManager.working"></app-loading>
                    Search
                </button>
            </div>
        </div>
    </div>
</form>

<ng-container *ngIf="!emptyListShowOnce">
    <div *ngIf="searchManager.totalCount > 0 " class="row"
         [ngClass]="{'disabled-table': searchManager.working}">
        <div class="d-md-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <app-total-records [searchManager]="searchManager"></app-total-records>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
                <page-size
                        *ngIf="searchManager.totalCount > 0"
                        [searchManager]="searchManager"
                ></page-size>
            </div>
        </div>
    </div>


    <div class="p-3 px-4 bg-white background view-table">

        <div *ngIf="searchManager.list.length > 0"
             class="d-flex justify-content-between flex-wrap p-1 bg-white ms-3"
             style="border-radius: 5px;">
            <div class="pos">
                <mat-checkbox color="primary" *ngIf="!isDelegation"
                              (change)="$event ? toggleAllRows() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
                <span class="fw-medium primary-color" *ngIf="!isDelegation"> {{ this.selection.selected.length }}
                    Selected</span>
                <u class="fw-medium pointer primary-color" *ngIf="!isDelegation" (click)="clearSelection()">Clear</u>
            </div>

            <button *ngIf="callToActionText && shouldShowMap" class="btn btn-dark btn-lg" (click)="mapTo()">
                {{ callToActionText }}
            </button>
        </div>


        <div class="card search p-2 rounded" *ngIf="searchManager.list.length > 0">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">SN</th>
                        <th scope="col">Customer Value</th>
                        <th scope="col">Value Description</th>
                        <th scope="col">Date Created</th>
                        <th scope="col">Created By</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngIf="!searchManager._working; else stillLoading">
                        <ng-container *ngIf="searchManager.list.length > 0; else NoDataToDisplay">

                            <tr
                                    class="justify-content-center"
                                    *ngFor="let biz of searchManager.list; let i = index">
                                <td>
                                    <mat-checkbox *ngIf="!isDelegation" color="primary"
                                                  (click)="$event.stopPropagation()"
                                                  [checked]="selection.isSelected(biz?.id)"
                                                  (change)="$event ? selection.toggle(biz?.id) : null">
                                    </mat-checkbox>
                                    <mat-radio-button *ngIf="isDelegation"
                                                      color="primary"
                                                      (click)="$event.stopPropagation()"
                                                      [value]="biz?.id"
                                                      [checked]="selection.isSelected(biz?.id)"
                                                      (change)="radioSelection(biz?.id)">
                                    </mat-radio-button>
                                </td>
                                <td data-label="SN">{{ i + searchManager.offset + 1 }}</td>

                                <td class="text-wrap" data-label="Customer Value">
                                    {{ biz?.identifierDisplayName }}
                                </td>
                                <td data-label="Value Description">
                                    {{ (biz.name && biz.name.trim() !== '') ? helperService.cutText(biz.name, 40) : helperService.cutText(biz.description, 40) }}
                                    <span *ngIf="biz.description?.length >= 41" [placement]="'right'" [tooltip]="biz.description">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info"> </span>
                                            </span>
                                </td>
                                <td data-label="Date Created">{{ biz?.createdAt | datetime }}</td>

                                <td data-label="Created By">
                                    <div class="d-flex flex-wrap">
                                        <div>
                                            {{ biz?.createdByPojo?.name + ' - ' }}
                                        </div>
                                        <div class="text-primary">
                                            {{biz.createdByPojo?.email }}
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </ng-container>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

    <div
            *ngIf="searchManager.totalCount > 10"
            class="row py-2"
            [ngClass]="{ 'disabled-table': searchManager.working }"
    >
        <div class="col-12 d-flex justify-content-end">
            <pagination
                    [(ngModel)]="searchManager.page"
                    [boundaryLinks]="true"
                    [itemsPerPage]="searchManager.itemsPerPage"
                    [maxSize]="maxPageSize"
                    [rotate]="true"
                    [totalItems]="searchManager.totalCount"
                    firstText="&laquo;"
                    lastText="&raquo;"
                    nextText="&rsaquo;"
                    previousText="&lsaquo;"
            ></pagination>
        </div>
    </div>
</ng-container>


<empty-list
        *ngIf="(searchManager.list.length == 0 && !searchManager.working)"
        [description]="'NO CUSTOMER VALUE DATA'"
        [fullPage]="false"
        [@fadeInOut]></empty-list>

<ng-template #stillLoading>
    <tr>
        <td colspan="100%">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </td>
    </tr>
</ng-template>

<ng-template #NoDataToDisplay>
    <tr>
        <td class="text-center" colspan="100%">NO CUSTOMER VALUE DATA</td>
    </tr>
</ng-template>



